import { Toast } from 'bootstrap';

window.handleNotificationToasts = function(){

    return {
        errorToast: null,
        copyToast: null,
        initCopyToast: function(refs){
            this.errorToast = new Toast(refs.errorToast);
        },
        initErrorToast: function(refs){
            this.copyToast = new Toast(refs.copyToast);
        },
    };
}