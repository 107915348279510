function GrwRemoveWidget(e) {
    var t = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : "";
    e.textContent = t
}

function GrwGenerateHTMLElements(e) {
    if (!window.DOMParser) return !1;
    var t = new DOMParser;
    try {
        return t.parseFromString(e, "text/html")
    } catch (e) {
        return !1
    }
    return ""
}

function GrwAddScriptLink(e) {
    var t = document.createElement("script");
    t.src = e, document.body.appendChild(t)
}

function GrwAddScript(e, t, r) {
    var n = document.createElement("script");
    "" !== t && n.setAttribute("type", t), n.innerHTML = e, "application/ld+json" === t ? r.appendChild(n) : document.body.appendChild(n)
}

function GrwAddStylesheet(e) {
    /**
        This Script loads the stylesheets for the widget but also a google fonts script
        This is a GDPR Violation. Therefore, we load the stylesheets ourselves and leave out the google fonts script
    **/

    /*
    var t = document.head.querySelectorAll('link[rel="stylesheet"]'),
        r = !0;
    if (0 < t.length)
        for (var n = 0; n < t.length; n++)
            if (t[n].href === e.href) return void(r = !1);
    r && document.head.appendChild(e)
    */
}

function GrwRenderResponse(e, t) {
    if (void 0 !== t.code && 200 === t.code) {
        "" !== e.innerHTML.trim() && (e.innerHTML = "");
        var r = GrwGenerateHTMLElements(t.message),
            n = r.head.querySelectorAll('link[rel="stylesheet"]');
        if (0 < n.length)
            for (var i = 0; i < n.length; i++) GrwAddStylesheet(n[i]);
        e.appendChild(r.body.children[0]), GrwShrinkable(e);
        var d = r.body.querySelectorAll("script");
        if (0 < d.length)
            for (i = 0; i < d.length; i++) {
                var a, l = d[i];
                "" !== l.src ? GrwAddScriptLink(l.src) : (a = l.getAttribute("type"), GrwAddScript(l.textContent, a, e))
            }
    } else GrwRemoveWidget(e, "Unable to get widget")
}

function GrwSendRequest(t, e) {
    var r = new XMLHttpRequest;
    r.onreadystatechange = function() {
        var e;
        4 == this.readyState && (200 == this.status ? (e = JSON.parse(this.responseText), GrwRenderResponse(t, e)) : GrwRemoveWidget(t, "Unable to load widget"))
    }, r.open("GET", e, !0), r.send()
}

function GrwExtractAttributeValue(e, t) {
    var r = e.getAttribute(t);
    return null !== r && r.trim()
}

function GrwWidgetHtmlDataLink(e) {
    var t = GrwExtractAttributeValue(e, "data-uuid"),
        r = GrwExtractAttributeValue(e, "data-template"),
        n = GrwExtractAttributeValue(e, "data-lang"),
        i = GrwExtractAttributeValue(e, "data-theme"),
        d = GrwExtractAttributeValue(e, "data-filter"),
        a = "";
    return !1 === t ? (GrwRemoveWidget(e, "Provide 'data-uuid' in widget attribute"), !1) : (a += "uuid=" + t, !1 === r ? (GrwRemoveWidget(e, "Provide 'data-template' in widget attribute"), !1) : ("" !== a && (a += "&"), a += "template=" + r, !1 !== n && ("" !== a && (a += "&"), a += "lang=" + n), !1 !== d && ("" !== a && (a += "&"), a += "stars=" + d), !1 !== i && ("" !== a && (a += "&"), a += "theme=" + i), void GrwSendRequest(e, a = "https://grwapi.net/api/get_widget.php?" + a)))
}

function GrwWidgetLoader() {
    var e = document.getElementsByClassName("review-widget_net");
    if (0 < e.length)
        for (var t = 0; t < e.length; t++) GrwWidgetHtmlDataLink(e[t]);
    else console.error("Review-Widget.net Error: Add widget div in html")
}

function GrwShowMore(e, t) {
    var r = "grw-net-comment-" + (t ? "more-" : ""),
        n = document.getElementById("grw-overflow" + e);
    n.classList.contains(r + "hidden") && n.classList.remove(r + "hidden");
    var i = document.getElementById("grw-more-link" + e);
    i.classList.contains(r + "hidden") || i.classList.add(r + "hidden");
    var d = document.getElementById("grw-less-link" + e);
    d.classList.contains(r + "hidden") && d.classList.remove(r + "hidden")
}

function GrwShowLess(e, t) {
    var r = "grw-net-comment-" + (t ? "more-" : ""),
        n = document.getElementById("grw-overflow" + e);
    n.classList.contains(r + "hidden") || n.classList.add(r + "hidden");
    var i = document.getElementById("grw-more-link" + e);
    i.classList.contains(r + "hidden") && i.classList.remove(r + "hidden");
    var d = document.getElementById("grw-less-link" + e);
    d.classList.contains(r + "hidden") || d.classList.add(r + "hidden")
}

function GrwShrinkable(e) {
    var t = e.getElementsByClassName("grw-net-more-shrinkable"),
        r = !0;
    if (0 === t.length && (t = e.getElementsByClassName("grw-net-shrinkable"), r = !1), 0 < t.length) {
        var n = document.getElementsByClassName("grw-net" + (r ? "-more" : "") + "-shrinkable");
        void 0 === n.length && (n = {
            length: 0
        });
        for (var i = "grw-net-comment" + (r ? "-more" : ""), d = i + "-more-less", a = 0; a < t.length; a++) {
            var l, s, o, c, g = t[a];
            null !== g.querySelector("." + d) && 0 !== g.querySelector("." + d).length || 120 < (l = g.innerHTML.trim()).length && (s = l.substring(0, 120).replace(/\w+$/, ""), o = (r ? "more" : "") + n.length + a, c = l.substring(120, l.length), g.innerHTML = "<span>" + s + '<span class="' + i + '-hidden" id="grw-overflow' + o + '">' + c + '</span></span><div class="' + d + '" id="grw-more-link' + o + '" onclick="GrwShowMore(\'' + o + "', " + r + ');">...more</div><div class="' + i + "-hidden " + d + '" id="grw-less-link' + o + '" onclick="GrwShowLess(\'' + o + "', " + r + ');">...less</div>')
        }
    }
}
var grw_loaded;
void 0 !== grw_loaded && (grw_loaded = !1), window.addEventListener("load", function() {
    grw_loaded || (grw_loaded = !0, GrwWidgetLoader())
});