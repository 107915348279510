// assets/app.js
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

import './app/styles/fonts.css';
import './app/styles/bootstrap.scss';

import Alpine from 'alpinejs';

import './app/styles/simple-lightbox.min.css';
import './app/styles/leaflet.css';
import 'intl-tel-input/build/css/intlTelInput.css';

import './app/javascript/handlePhoneNumber.js';
import './app/javascript/handleNotificationToasts.js';
import './app/javascript/handleSalePopup.js';
import './app/javascript/handleCallbackForm.js';

import './grw/widget-carousel.min.css';
import './grw/widget.min.css';
import './grw/grw.js';

window.Alpine = Alpine
Alpine.start();