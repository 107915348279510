import Cookies from 'js-cookie'

window.handleSalePopup = function(doNotShowPathArray){
    return {
        open: false,
        init: function(){
            if(doNotShowPathArray.includes(window.location.pathname)) return;
            
            //Paths may have a wildcard * at the end - test for the pathname to only start with an exception
            let isException = false
            doNotShowPathArray.forEach(doNotShowPath => {
                if(!doNotShowPath.endsWith('*') || isException) return;
                if(window.location.pathname.startsWith(doNotShowPath.replace('*', ''))) isException = true;
            });

            if(Cookies.get("limitedsticky") !== "true" && !isException) this.open = true; 
        },
        closeAction: function(){
            this.open = false;
            var d = new Date();
            d.setTime(d.getTime() + (1*24*60*60*1000));
            var expires = d.toUTCString();
            document.cookie = "limitedsticky=true; expires=" + expires + "; path=/";
        },
    }
}