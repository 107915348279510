import Cookies from 'js-cookie';

window.handlePhoneNumber = function(standardNumber, standardDisplay, organicLandingpageNumber, organicLandingpageDisplay, googleAdsSerpNumber, googleAdsSerpDisplay, facebookNumber, facebookDisplay){

    return {
        getPhoneNumberLink: function() {
            if(window.location.pathname.includes("/kundenservice/")) return standardNumber;

            if(Cookies.get("clickref") === "localgeneric") return googleAdsSerpNumber;
            if(Cookies.get("clickref") === "organic") return organicLandingpageNumber;
            if(Cookies.get("clickref") === "facebook") return facebookNumber;
            
            return standardNumber;
        },
        getPhoneNumberDisplay: function() {
            if(window.location.pathname.includes("/kundenservice/")) return standardDisplay;

            if(Cookies.get("clickref") === "localgeneric") return googleAdsSerpDisplay;
            if(Cookies.get("clickref") === "organic") return organicLandingpageDisplay;
            if(Cookies.get("clickref") === "facebook") return facebookDisplay;
            
            return standardDisplay;
        },
    }
}