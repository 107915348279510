import intlTelInput from 'intl-tel-input';
import Cookies from 'js-cookie'

window.handleCallbackForm = function(){

    /*
    Refs:
        phoneIti
    */

    return {
        message: "",
        phone: "",
        email: "",
        contactMode: "whatsapp", //whatsapp / email
        loading: false,
        phoneIti: null,
        success: false,
        
        initOrderForms: function(){
            if(!this.$refs.phoneIti) return;
            this.phoneIti = intlTelInput(this.$refs.phoneIti, {
                initialCountry: "de",
                preferredCountries: ["de", "at", "ch"],
                utilsScript: "/build/intlib_utils.js",
                customContainer: "w-100",
                customPlaceholder: function(){ return "Ihre Handynummer" }
            });
        },
        get isOrderFormValid(){
            const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
            return (this.phone.length > 0 && this.phoneIti.isValidNumber()) && emailRegex.test(this.email);
        },
        sendOrderForm: function(){
            this.loading = true;
            let requestBody = {
                phone: this.phoneIti.getNumber(intlTelInputUtils.numberFormat.E164),
                email: this.email,
                message: this.message,
                contactMode: this.contactMode,
                configurationCode: this.configurationCode,
                utmSource: Cookies.get('ms_utm_source'),
                utmContent: Cookies.get('ms_utm_content'),
                utmAdset: window.location.href,
            }
            fetch('/contact', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify(requestBody)
            })
            .then(response => response.json())
            .then((response) => {
                this.success = true;
                dataLayer.push({
                    event: 'ms_custom_conversion',
                    conversionType: "ContactForm"
                });
            })
            .catch((e) => {
                console.log("Error", e);
                this.$dispatch("show-error-toast");
            })
            .finally(() => {
                this.loading = false;
                this.phone = "";
                this.message = "";
                this.email = "";
            })
        },
    }
}